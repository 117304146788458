#app {
  height: 100vh !important;
  overflow: hidden;
}

.logo-text {
  flex: 1;
  display:flex;
  align-items:center;
  flex-wrap: wrap;
  justify-content: center;
}
.spinner {
  margin-top: 64px;
  flex: 1;
}

.text_container {
  width: 100vw;
  position: fixed;
  bottom: 16px;
}

.nes-container {
  border-image-repeat: inherit !important;
  background-color: #212529bb !important;
}

.nes-container.white-screen {
  background-color: white !important;
}

::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.screen {
  background-color: rgba(0,0,0,0.9);
  display: flex;
  flex-flow: column nowrap;
  color: #ffffff;
  animation: fade_in 2s;
  text-shadow: 3px 3px 3px #333;
  border-radius: 1rem;
  padding: 1rem;
  font-size: 1rem;
}

.screen.queue {
  background-color: transparent;
}

.screen_title {
  font-weight: 900;
  margin-bottom: 1rem;
  letter-spacing: 4px;
  font-size: 140%;
}

.screen_close {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
    /*
    background: white;
    padding: 1rem;
    border-radius: 100%;
    box-shadow: 2px 1px 1px #aaa;
    */
}

.screen_table {
    border: none;
    border-spacing: 20px;
    border-collapse: separate;
}
.screen_table th {
  font-size: 90%;
}

.screen_table td {
  font-size: 80%;
}

.results_ready {
  animation: blinker 1s linear infinite;
  text-transform: uppercase;
  color: #22ee22;
  font-size: 100%;
  white-space: nowrap;
}

.result_row {
  animation: fade_in 1s;
}

.pass {
  color: #22ee22;
}

.fail {
  color: #ff2222;
}

.info {
  color: #209cee;
}
.pies_container {
  display: flex;
  margin-bottom: 4rem;
}

.pie_title {
  margin-bottom: .5rem;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  letter-spacing: -1px;
}

@keyframes fade_out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.lake {
  height: 100vh;
}

.menu, .back {
  font-size: 12px;
  padding: 6px;
  position: fixed;
  top: 0;
  z-index: 9999999999;
  color: #fff;
}

.menu {
  right: 0;
}
.back {
  left: 0;
}

.skip {
  font-size: 16px;
  padding: 16px;
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(255,255,255,0.1);
  z-index: 9999999999;
  color: #fff;
}
.skip:hover {
  background: rgba(255,255,255,0.8)
}

.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.2); }

.button {
  margin-bottom: .5rem;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

html, body {
  font-family: "Press Start 2P" !important; /* override nes.css */
  background-color: black !important;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1 {
  font-weight: 400;
  font-size: 64px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.loading-container {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index:9999999;
  background: black;
}

/* nes-react styles */
.nes-checkbox-parent span {
  cursor: pointer;
  line-height: 2;
}

.nes-radio-group {
  margin-left: 15px;
  margin-right: 15px;

.nes-radio-group span {
    cursor: pointer;
  }
}

.nes-table {
  margin-top: 5px;
  margin-bottom: 5px;
}

.nes-field {
  margin: 10px 5px;
}

.nes-list {
  padding-left: 0;
  list-style: none;
}
.nes-list li::before {
  color: white !important;
  top: 4px !important;
}

.nes-checkbox-parent {
  margin: .5rem 0!important;
}


:root {
  --animate-delay: 0.5s;
}

.scroll {
  max-height: 70vh;
  overflow-y: scroll;
}


.bar1, .bar2, .bar3 {
  width: 20px;
  height: 3px;
  background-color: white;
  margin: 2px 0;
  transition: 0.4s;
}
.open .bar1 {
  transform: rotate(-45deg) translate(-9px, 6px);
}
.open .bar2 {opacity: 0;}
.open .bar3 {
  transform: rotate(45deg) translate(-8px, -8px);
}
.menu-button {
  display: flex;
  flex-direction: column;
}

.menu-buttons{
  position: absolute;
  top: 60px;
  right: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}


.nes-btn{
  border-image-repeat: stretch !important;
}

.finish-day {
  position: absolute;
  bottom:0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999999;
}

.finished-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.fade-container {
  font-size: 12px;
}

.tree-buttons {
  font-size: 12px;
  padding: 8px 32px;
  max-height: 50vh;
  overflow-y: auto;
  text-align: center;
}

.loading-progress {
  width: 100%;
}
.loading-progress::-webkit-progress-value {
    transition: width 0.5s;
}

.accept {
  font-size: 12px;
}
